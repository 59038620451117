*, :before, :after {
  box-sizing: border-box;
}

body, h1, h2, h3, h4, p, figure, blockquote, dl, dd {
  margin: 0;
}

ul[role="list"], ol[role="list"] {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  line-height: 1.5;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

img, picture {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *, :before, :after {
    scroll-behavior: auto !important;
    transition-duration: .01ms !important;
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
  }
}

@font-face {
  font-family: Makinas4F;
  src: url("Makinas-4-Flat-subset.b82c3bec.woff2") format("woff2"), url("Makinas-4-Flat-subset.7615c3c8.woff") format("woff"), url("Makinas-4-Flat-subset.38727b7f.otf") format("otf");
}

.heading {
  color: #821dbf;
  font-family: Makinas4F;
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
}

.heading:before {
  content: "■ ";
}

.heading:after {
  content: " ■";
}

html {
  scroll-behavior: smooth;
}

.header {
  z-index: 100;
  background: #ffffffbf;
  justify-content: flex-end;
  gap: 40px;
  padding: 32px;
  font-family: Makinas4F;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

@media screen and (width <= 768px) {
  .header {
    gap: 20px;
    padding: 24px;
  }
}

.header .headerMenu {
  color: #821dbf;
  font-size: 16px;
  text-decoration: none;
}

.header .headerMenu:hover {
  opacity: .7;
}

.heroView {
  z-index: 0;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 88px 32px 40px;
  display: flex;
}

@media screen and (width <= 768px) {
  .heroView {
    padding: 72px 24px 40px;
  }
}

.heroView .heroView__logoWrapper {
  width: 100%;
}

.heroView .heroView__content {
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  width: 100%;
  max-width: 1080px;
  display: flex;
}

@media screen and (width <= 768px) {
  .heroView .heroView__content {
    flex-direction: column;
    position: relative;
  }
}

.heroView .heroView__avaterImg {
  max-height: 100vh;
  display: none;
}

.heroView .heroView__avaterImg.--active {
  display: inline-block;
}

.heroView .heroView__greeting {
  color: #821dbf;
  background: #fffc;
  border: 1px solid #821dbf;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  display: flex;
}

@media screen and (width <= 768px) {
  .heroView .heroView__greeting {
    padding: 24px;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
  }
}

.heroView .greeting__headingWrapper {
  flex-direction: column;
  gap: 16px;
  font-family: Makinas4F;
  display: flex;
}

.heroView .greeting__subHeading {
  font-size: 24px;
  line-height: 1;
}

@media screen and (width <= 768px) {
  .heroView .greeting__subHeading {
    font-size: 20px;
  }
}

.heroView .greeting__heading {
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
}

@media screen and (width <= 768px) {
  .heroView .greeting__heading {
    font-size: 28px;
  }
}

.heroView .greeting__content {
  max-width: 480px;
  font-size: 16px;
  line-height: 2;
}

.heroView .changeAvaterImageButtons {
  gap: 24px;
  display: flex;
}

@media screen and (width <= 768px) {
  .heroView .changeAvaterImageButtons {
    gap: 8px;
    position: absolute;
    top: 32px;
    right: 24px;
  }
}

.heroView .changeAvaterImageButtons .changeAvaterImageButtons__avaterIcon {
  cursor: pointer;
  border: 1px solid #bdbdbd;
  border-radius: 50%;
  width: 64px;
  height: 64px;
}

.heroView .changeAvaterImageButtons .changeAvaterImageButtons__avaterIcon.--active {
  cursor: default;
  border-color: #821dbf;
}

@media screen and (width <= 768px) {
  .heroView .changeAvaterImageButtons .changeAvaterImageButtons__avaterIcon {
    width: 40px;
    height: 40px;
  }
}

.data {
  flex-direction: column;
  align-items: center;
  gap: 64px;
  padding: 88px 40px 0;
  display: flex;
}

@media screen and (width <= 768px) {
  .data {
    padding: 72px 24px 0;
  }
}

.data .dataTable {
  border-collapse: collapse;
}

.data .dataTable .dataTable__row {
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  padding: 4px 0;
}

@media screen and (width <= 768px) {
  .data .dataTable .dataTable__row {
    border-bottom: 0;
    flex-direction: column;
    padding: 16px 0;
    display: flex;
  }

  .data .dataTable .dataTable__row:last-of-type {
    border-bottom: 1px solid #444;
  }
}

.data .dataTable .dataTable__heading, .data .dataTable .dataTable__data {
  color: #444;
  font-size: 16px;
  line-height: 2;
}

@media screen and (width <= 768px) {
  .data .dataTable .dataTable__heading, .data .dataTable .dataTable__data {
    line-height: 1.7;
  }
}

.data .dataTable .dataTable__heading {
  text-align: left;
  padding-right: 40px;
  font-weight: normal;
}

@media screen and (width <= 768px) {
  .data .dataTable .dataTable__heading {
    font-size: 18px;
  }
}

.data .dataTable .dataTable__link {
  color: #2f80ed;
}

.data .dataTable .dataTable__link:hover {
  opacity: .8;
}

.content {
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 88px 40px 40px;
  display: flex;
}

@media screen and (width <= 768px) {
  .content {
    padding: 72px 24px 0;
  }
}

.content .contentWrapper {
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  max-width: 1080px;
  display: flex;
}

@media screen and (width <= 768px) {
  .content .contentWrapper {
    flex-direction: column;
  }
}

.content .contentItem {
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: calc(50% - 20px);
  display: flex;
}

@media screen and (width <= 768px) {
  .content .contentItem {
    width: 100%;
  }
}

.content .contentItem .contentItem__heading {
  color: #444;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
}

.content .contentItem .contentItem__body {
  aspect-ratio: 16 / 9;
  width: 100%;
  overflow-y: scroll;
}

.footer {
  place-items: center;
  padding: 40px;
  font-family: Makinas4F;
  display: grid;
}

.footer .footer__text {
  color: #444;
  font-size: 16px;
  line-height: 2;
}

#loading {
  z-index: 500;
  background: #fff;
  width: 100vw;
  height: 100vh;
  transition: all 1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
}

#loading .loadingText {
  color: #821dbf;
  font-size: 16px;
  position: absolute;
  top: calc(50% - 30px);
  left: 32px;
}

#loading.--loaded, #loading.--loaded .loadingText {
  opacity: 0;
}

#loading #loadingPercentage {
  background: linear-gradient(90deg, #f8c0ea 0%, #a58fd2 100%);
  width: 10%;
  height: 10px;
  transition: all .5s;
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
}

#loading #loadingPercentage.--loaded {
  opacity: 0;
}
/*# sourceMappingURL=index.1e9fca39.css.map */
