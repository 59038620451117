// component

@font-face
  font-family: Makinas4F
  src: url('./font/Makinas-4-Flat-subset.woff2') format('woff2'), url('./font/Makinas-4-Flat-subset.woff') format('woff'), url('./font/Makinas-4-Flat-subset.otf') format('otf')


.heading
  font-size: 36px
  line-height: 1
  color: #821DBF
  font-weight: normal
  font-family: Makinas4F
  &::before
    content: '■ '
  &::after
    content: ' ■'

// sections
html
  scroll-behavior: smooth

.header
  display: flex
  gap: 40px
  justify-content: flex-end
  padding: 32px
  background: rgba(255,255,255, .75)
  position: fixed
  top: 0
  left: 0
  right: 0
  font-family: Makinas4F
  z-index: 100
  @media screen and (max-width: 768px)
    padding: 24px
    gap: 20px
  .headerMenu
    font-size: 16px
    color: #821DBF
    text-decoration: none
    &:hover
      opacity: 0.7

.heroView
  padding: 88px 32px 40px
  display: flex
  flex-direction: column
  align-items: center
  gap: 40px
  z-index: 0
  @media screen and (max-width: 768px)
    padding: 72px 24px 40px

  .heroView__logoWrapper
    width: 100%

  .heroView__content
    width: 100%
    max-width: 1080px
    display: flex
    justify-content: space-between
    align-items: center
    gap: 40px
    @media screen and (max-width: 768px)
      position: relative
      flex-direction: column

  .heroView__avaterImg
    max-height: 100vh
    display: none
    &.--active
      display: inline-block

  .heroView__greeting
    padding: 40px
    background: rgba(255,255,255,0.8)
    border: 1px solid #821DBF
    display: flex
    flex-direction: column
    gap: 24px
    color: #821DBF
    @media screen and (max-width: 768px)
      position: absolute
      left: 0
      right: 0
      top: 50%
      padding: 24px

  .greeting__headingWrapper
    display: flex
    flex-direction: column
    gap: 16px
    font-family: Makinas4F
  .greeting__subHeading
    font-size: 24px
    line-height: 1
    @media screen and (max-width: 768px)
      font-size: 20px
  .greeting__heading
    font-size: 36px
    @media screen and (max-width: 768px)
      font-size: 28px
    line-height: 1
    font-weight: normal

  .greeting__content
    font-size: 16px
    line-height: 2
    max-width: 480px

  .changeAvaterImageButtons
    display: flex
    gap: 24px
    @media screen and (max-width: 768px)
      position: absolute
      gap: 8px
      right: 24px
      top: 32px
    .changeAvaterImageButtons__avaterIcon
      height: 64px
      width: 64px
      border-radius: 50%
      border: 1px solid #BDBDBD
      cursor: pointer
      &.--active
        border-color: #821DBF
        cursor: default
      @media screen and (max-width: 768px)
        width: 40px
        height: 40px

.data
  padding: 88px 40px 0
  display: flex
  flex-direction: column
  align-items: center
  gap: 64px
  @media screen and (max-width: 768px)
    padding: 72px 24px 0

  .dataTable
    border-collapse: collapse
    .dataTable__row
      border-top: 1px solid #444444
      border-bottom: 1px solid #444444
      padding: 4px 0
      @media screen and (max-width: 768px)
        display: flex
        flex-direction: column
        padding: 16px 0
        border-bottom: 0
        &:last-of-type
          border-bottom: 1px solid #444444
    .dataTable__heading,
    .dataTable__data
      font-size: 16px
      line-height: 2
      @media screen and (max-width: 768px)
        line-height: 1.7
      color: #444444
    .dataTable__heading
      font-weight: normal
      text-align: left
      padding-right: 40px
      @media screen and (max-width: 768px)
        font-size: 18px
    .dataTable__link
      color: #2F80ED
      &:hover
        opacity: 0.8

.content
  padding: 88px 40px 40px
  display: flex
  flex-direction: column
  align-items: center
  gap: 40px
  @media screen and (max-width: 768px)
    padding: 72px 24px 0

  .contentWrapper
    display: flex
    flex-wrap: wrap
    gap: 40px
    max-width: 1080px
    width: 100%
    @media screen and (max-width: 768px)
      flex-direction: column

  .contentItem
    width: calc(50% - 20px)
    display: flex
    flex-direction: column
    align-items: center
    gap: 16px
    @media screen and (max-width: 768px)
      width: 100%
    .contentItem__heading
      color: #444444
      font-size: 24px
      line-height: 1.5
      font-weight: normal
    .contentItem__body
      width: 100%
      aspect-ratio: 16/9
      overflow-y: scroll

.footer
  padding: 40px
  display: grid
  place-items: center
  font-family: Makinas4F
  .footer__text
    font-size: 16px
    line-height: 2
    color: #444444

#loading
  position: fixed
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  background: #ffffff
  z-index: 500
  transition: 1s ease-in-out
  .loadingText
    position: absolute
    font-size: 16px
    color: #821DBF
    left: 32px
    top: calc(50% - 30px)
  &.--loaded
    opacity: 0
    .loadingText
      opacity: 0
  #loadingPercentage
    position: absolute
    top: calc(50% - 5px)
    left: 0
    width: 10%
    height: 10px
    background: linear-gradient(90deg, #F8C0EA 0%, #A58FD2 100%)
    transition: .5s
    &.--loaded
      opacity: 0
